import { Grid, makeStyles } from '@material-ui/core'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React, { useEffect, useRef } from 'react'
import { Layout } from '../component/Layout'
import LegalCookies from '../component/page_component/LegalCookies'
import LegalLinks from '../component/page_component/LegalLinks'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  legalContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  legalItem: {
    scrollSnapType: 'y',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
    },
  },
}))

const LegalCookiesPage: React.FC<PageProps> = (props) => {
  const { location } = props
  const classes = useStyles()
  const cookiesRef = useRef<HTMLInputElement>(null)
  const intl = useIntl()

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location?.hash &&
      window.location.hash.split('#').length == 2
    ) {
      const id = window.location.hash.split('#')[1]
      if (id === 'cookie-settings' && cookiesRef && cookiesRef.current) {
        cookiesRef.current.scrollIntoView()
      }
    }
  })

  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`
  return (
    <Layout location={location} title={intl.formatMessage({ id: 'PageTitleLegalCookies' })}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <LegalLinks />
      <Grid container className={classes.legalContainer}>
        <div id={'cookie-settings'} ref={cookiesRef} className={classes.legalItem}>
          <LegalCookies isTitleHanging={true} />
        </div>
      </Grid>
    </Layout>
  )
}

export default LegalCookiesPage
